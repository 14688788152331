import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { AuthService } from 'src/app/modules/auth/services/auth.service'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser: any = localStorage.getItem('authLocalStorageToken');

        if (currentUser) {
            //logged in so return true
            return true;
        }

        //   // // not logged in so redirect to login page with the return url
        this.authService.logOut();
        return false;
    }
}
